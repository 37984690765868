@import "./variables";

@mixin text-wrapped {
  word-wrap: break-word;
  word-break: break-all;
  text-wrap: pretty;
}

@mixin one-line-text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin icon-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 0.35s;

  @media (max-width: $sm) {
    width: 34px;
    height: 34px;
  }

  &:hover {
    border: 1px solid $buttons-primary-hover;
    background-color: $buttons-primary-hover;

    svg path {
      stroke: $white;
    }
  }

  &:active {
    border: 1px solid $buttons-primary-pressed;
    background-color: $buttons-primary-pressed;

    svg path {
      stroke: $white;
    }
  }

  &:disabled {
    cursor: default;
    color: $icon-primary-border-checkbox-radio-button;
    border: 1px solid transparent;
    background-color: $buttons-primary-disabled;

    svg {
      color: $icon-primary-border-checkbox-radio-button;

      & path {
        stroke: $icon-primary-border-checkbox-radio-button;
      }
    }
  }
}

@mixin hint {
  position: absolute;
  top: 35px;
  padding: 8px 10px;
  display: inline-block;
  background-color: $buttons-primary-pressed;
  border: 1px solid $buttons-primary-pressed;
  border-radius: 2px;
  color: $white;
  font-size: 16px;
  transition: all 0.2s;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.1);
  transform-origin: top center;
  pointer-events: none;
  white-space: nowrap;
  z-index: 1;

  @media (max-width: $sm) {
    top: 30px;
    font-size: 12px;
  }

  &::after {
    content: "";
    position: absolute;
    top: -5px;
    left: calc(50% - 6px);
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom-color: $buttons-primary-pressed;
    border-top: 0;
  }
}

@mixin large-hint-in-row {
  @include hint;
  top: 95%;
  left: 0;
  z-index: 2;

  &::after {
    content: "";
    position: absolute;
    top: -5px;
    left: calc(10% - 6px);
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom-color: $buttons-primary-pressed;
    border-top: 0;
  }
}

@mixin icon-button-with-hint {
  @include icon-button;

  &:hover {
    .description {
      opacity: 1;
      visibility: visible;
      transform: scale(1) translateY(10px);
    }
  }

  .description {
    @include hint;
  }
}

@mixin icon-button-highlight {
  @include icon-button;

  border: 1px solid $buttons-primary-hover;
  background-color: $buttons-primary-hover;
  z-index: 1;

  .description {
    @include hint;
  }

  svg path {
    stroke: $white;
  }

  &:hover {
    .description {
      opacity: 1;
      visibility: visible;
      transform: scale(1) translateY(10px);
    }
  }
}

@mixin icon-favorites {
  .description {
    @include hint;
    top: 20px;
  }

  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 0.35s;
  z-index: 0;

  svg {
    transition: 0.35s;
    fill: transparent;
  }

  svg path {
    stroke: $icon-primary-border-checkbox-radio-button;
  }

  &:hover {
    z-index: 1;

    .description {
      opacity: 1;
      visibility: visible;
      transform: scale(1) translateY(10px);
    }

    svg path {
      stroke: $buttons-primary-hover;
    }
  }

  &:active {
    z-index: 1;

    svg {
      fill: $buttons-primary-pressed;
    }

    svg path {
      stroke: $buttons-primary-pressed;
    }
  }

  &:active::after,
  &:hover:after {
    top: 34px;
  }
}

@mixin email {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  color: $text-secondary-secondary;
  cursor: pointer;
  transition: 0.35s;

  &:hover {
    color: $text-hover;
  }

  &:active {
    color: $buttons-primary-pressed;
  }
}

@mixin link {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  color: $buttons-primary-default;
  cursor: pointer;
  transition: 0.35s;

  &:hover {
    color: $text-hover;
  }

  &:active {
    color: $buttons-primary-pressed;
  }
}

@mixin label {
  display: flex;
  gap: 14.25px;
  align-items: center;

  &:hover {
    [data-component="hidden"]:not(:checked)+[data-component="radio"]:after {
      transform: scale(2.2);
    }
  }
}

@mixin tableRow {
  transition: 0.35s;

  &:hover {
    &::after {
      background-color: $buttons-primary-hover;
    }
  }

  &:active {
    &::after {
      background-color: $buttons-primary-pressed;
    }
  }
}

@mixin card {
  padding: 24px;
  background-color: $white;
  box-shadow: 0px 2px 4px rgba(14, 56, 100, 0.08);
  border-radius: 8px;
}

@mixin scroll {
  overflow-y: auto;
  visibility: visible;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $line-secondary;
    border-left: 4px solid $white;
    background-clip: padding-box;
  }
}

@mixin btn-hint-top {
  top: -60px;
  transform-origin: bottom center;

  @media (max-width: $sm) {
    top: -60px;
    font-size: 12px;
  }

  &::after {
    top: calc(100%);
    left: calc(50% - 6px);
    right: 13px;
    position: absolute;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom-color: $buttons-primary-pressed;
    border-top: 0;
    transform: rotate(180deg);
  }
}

@mixin btn-hint-top-end {
  @include btn-hint-top;
  right: 0;

  &::after {
    left: initial;
  }
}

@mixin btn-hint-bottom-end {
  top: 35px;
  right: 0;
  transform-origin: top right;

  @media (max-width: $sm) {
    top: 30px;
    font-size: 12px;
  }

  &::after {
    content: "";
    top: -5px;
    left: initial;
    right: 13px;
    position: absolute;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom-color: $buttons-primary-pressed;
    border-top: 0;
  }
}


@mixin shadows {
  position: relative;
  height: 100%;
  overflow: hidden;
  background-image: transparent;
  transition: 0.35s;
  border-top: 1px solid rgba(14, 56, 100, 0.07);
  border-bottom: 1px solid rgba(14, 56, 100, 0.07);

  &TopBorder {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 30px;
      background-image: linear-gradient(to top, transparent, white 100%);
      z-index: 10;
    }
  }

  &BottomBorder {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 30px;
      background-image: linear-gradient(to bottom, transparent, white 100%);
    }
  }
}

@mixin clickableCard {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid $line-primary;
  cursor: pointer;
  transition: 0.35s;

  &Validating {
    border: 1px solid $line-secondary;
  }

  &:hover {
    border: 1px solid $buttons-primary-hover;
  }

  &:active {
    border: 1px solid $buttons-primary-pressed;
  }
}