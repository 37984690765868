@import "../../../assets/scss/helpers/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  gap: 4px;
  border-radius: 25px;
  border: 1px solid $text-secondary-secondary;
  padding: 1px 8px;
  text-wrap: nowrap;
  p {
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
  }
  @media (max-width: $sm) {
    p {
      font-size: 8px;
      font-weight: 700;
      line-height: 16px;
    }
  }
}

.confirmed {
  background-color: $background-green;
  color: $success;
  border: 1px solid #07831c;

  svg {
    width: 14px;
    height: 14px;
    color: $success;
    @media (max-width: $sm) {
      width: 12px;
      height: 12px;
    }
  }
}

.canceled {
  background-color: rgba(255, 58, 64, 0.08);
  color: $alert;
  border: 1px solid $alert;

  svg {
    width: 10px;
    height: 10px;
  }
}

.waiting {
  background-color: $background-grey;
  color: $text-secondary-secondary;
}

//TODO после макета перенести в константы
.processing {
  background-color: #0a58e01a;
  color: #0a58e0;
  border: 1px solid #0a58e0;

  svg {
    width: 18px;
    height: 18px;
    @media (max-width: $sm) {
      width: 12px;
      height: 12px;
    }
  }
}

.default {
  background-color: transparent;
  color: $text-secondary-secondary;
  border: 1px solid $line-primary;
}

.error {
  background-color: transparent;
  color: $alert;
  border: 1px solid $alert;

  svg {
    height: 14px;
    width: 16px;
  }
}

.warning {
  background-color: $warning-background;
  color: $warning-main;
  border: 1px solid $warning-main;
}

.paused {
  background-color: transparent;
  color: $text-secondary-secondary;
  border: 1px solid $text-secondary-secondary;
}

.warn {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: $alert;
}
