@import "../../../../../assets/scss/helpers/variables";
@import "../../../../../assets/scss/helpers/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 976px;
  height: 85vh;

  @media (max-width: $sm) {
    width: 760px;
    height: 88vh;
  }
}

.btnsWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.title {
  font-size: 26px;
  line-height: 33px;
  font-weight: 600;
  margin-bottom: 8px;

  @media (max-width: $sm) {
    font-size: 20px;
    line-height: 25px;
  }
}

.subTitle {
  font-size: 16px;
  line-height: 23px;
  color: $text-secondary-secondary;
}

.warnWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.listContainer {
  @include shadows;
  padding: 10px 0;

  .modelsList {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0px;
    }
  }
}

.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}