@import "../../../assets/scss/helpers/variables";

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    transition: 0.35s;

    &Open {
        visibility: visible;
        opacity: 1;
    }

    &Close {
        visibility: hidden;
        opacity: 0;
    }
}

.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(21, 20, 27, 0.2);
    z-index: 0;
}

.content {
    background-color: $white;
    padding: 24px;
    position: relative;
    z-index: 1;
    border-radius: 8px;
}

.closeBtn {
    position: absolute;
    z-index: 1;
    top: 8px;
    right: 8px;
    width: 34px;
    height: 34px;
    color: $text-header-dark;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.35s;
    border-radius: 2px;

    &:hover {
        background-color: $buttons-primary-hover;
        color: $white;
    }

    &:active {
        background-color: $buttons-primary-pressed;
        color: $white;
    }
}