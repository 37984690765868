@import "../../../assets/scss/helpers/variables";
@import "../../../assets/scss/helpers/mixins";

.card {
  position: relative;
  @include clickableCard;
}

.mainInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2px;

  .rowWrap {
    display: flex;
    gap: 4px;
  }
}

.name {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: $text-header-dark;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.info {
  display: flex;
  gap: 4px;
  align-items: center;

  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: $gray-concrete;
  }

  .category {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.dot {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: $line-primary;
}

.divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: $buttons-primary-disabled;
  border-radius: 2px;
}

.allianceDivider {
  margin-top: auto;
}

.secondaryInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
}

.type {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $text-header-dark;
  text-overflow: ellipsis;
  overflow: hidden;
}

.weight {
  display: inline-flex;
  gap: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: $gray-concrete;

  .count {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    color: $text-header-dark;
  }
}

.alliance {
  @include link;
  font-size: 14px;
  text-decoration: none;
  word-wrap: break-word;
  padding-right: 30px;
}

.paidIconWrap {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 20px;
  right: 20px;
  transition: 0.35s;

  svg {
    width: 24px;
    height: 24px;
    transition: 0.35s;
  }


  &:hover {
    color: $buttons-primary-hover;

    .description {
      opacity: 1;
      visibility: visible;
      transform: scale(1) translateY(10px);
    }
  }

}

.statusWithDescription {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: center;

  &:hover {
    color: $buttons-primary-hover;

    .description {
      opacity: 1;
      visibility: visible;
      transform: scale(1) translateY(10px);
    }
  }
}

.description {
  @include hint;
  top: calc(100%);

  @media (max-width: $sm) {
    top: calc(100%);
  }
}