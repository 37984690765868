@import "../../../../assets/scss/helpers/variables";
@import "../../../../assets/scss/helpers/mixins";


.wrapper {
    width: 310px;
}

.title {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 8px;
}

.subtitle {
    color: $text-secondary-secondary;
    font-size: 16px;
}

.counter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 60px 0;
}

.text {
    font-size: 24px;
}

.btnWrapper {
    display: flex;
    justify-content: end;
}

.btn {
    width: 34px;
    height: 34px;
    color: $text-header-dark;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.35s;
    border-radius: 50%;
    font-size: 16px;
    background-color: $buttons-primary-hover;
    color: $white;

    &:hover,
    &:active {
        background-color: $buttons-primary-pressed;
        color: $white;
    }

    &:disabled {
        cursor: default;
        background-color: $icon-primary-counter;
    }
}
